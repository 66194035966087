import { useEffect } from "react";

// react-router components

import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "./assets/theme";
import Presentation from "./layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "./routes";


// Pages
import AboutUs from "./layouts/pages/landing-pages/about-us";
import ContactUs from "./layouts/pages/landing-pages/contact-us";
import Careers from "./layouts/pages/landing-pages/careers";
import Partners from "./layouts/pages/landing-pages/partners";

// Import Legal Pages
import PrivacyPolicy from "./layouts/pages/legal/privacy-policy";
import TermsConditions from "./layouts/pages/legal/terms-conditions";
import RefundPolicy from "./layouts/pages/legal/refund-policy";
import Pricing from "./pages/pricing";

export default function App() {
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />

        
        <Route path="/pages/landing-pages/about-us" element={<AboutUs />} />
        <Route path="/pages/landing-pages/careers" element={<Careers />} />
        <Route path="/pages/landing-pages/partners" element={<Partners />} />
        <Route path="/pages/landing-pages/contact-us" element={<ContactUs />} />

        <Route path="/pages/pricing" element={<Pricing />} />

        <Route path="/pages/legal/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/pages/legal/terms-conditions" element={<TermsConditions />} />
        <Route path="/pages/legal/refund-policy" element={<RefundPolicy />} />


        
      </Routes>
      </Router>
    </ThemeProvider>
  );
}
