// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import MKButton from "../../components/MKButton";
import { Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import MKSocialButton from "../../components/MKSocialButton";

// Pricing page sections
import Header from "./section/header";
//import AboutUs from "./section/AboutUs";
//import PricingSection from "./section/Pricing";
//import LifetimeMembership from "./section/LifetimeMembership";
//import Testimonials from "./section/Testimonials";
//import Trust from "./section/Trust";
//import Faq from "./section/Faq";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";

function Pricing() {
  return (
    <>
       <DefaultNavbar
        routes={routes}
        
        sticky
      />
      <Header />
      <MKBox p={3}>
     {/*}   <AboutUs />
        <PricingSection />
        <LifetimeMembership />
        <Testimonials />
        <Trust />
  <Faq />*/}

     

      <Grid container spacing={3}>
       
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
              <MKBox mt={2} mb={3}>
          We would appreciate the opportunity to better understand your specific requirements in order to provide you with a tailored pricing solution. Please feel free to reach out to us so that we can discuss your needs in detail. 
          </MKBox>

                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Get in touch with us and begin your journey.
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Transformation at the Grass Roots level.
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Join%20@Cynefian%20on%20this%20incredible%20journey%20as%20we%20embark%20on%20transforming%20industries%20and%20redefining%20possibilities.%20Stay%20tuned%20for%20exciting%20updates,%20product%20launches,%20and%20insightful%20thought%20leadership%20content.%20%20%23Cynefian%20%23Innovation%20%23DigitalTransformation%20%23TechnologyRevolution"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
               
                <MKSocialButton
                  component="a"
                  href="https://www.linkedin.com/company/cynefian"
                  target="_blank"
                  color="linkedin"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-linkedin" />
                  &nbsp;Follow
                </MKSocialButton>
                <MKButton
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to="/pages/landing-pages/contact-us"
                >
                  Get in touch
                </MKButton>


               {/*
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
            */}
              </Grid>
            </Grid>

      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Pricing;
