import React from "react";
import BaseLayout from "../../../layouts/sections/components/BaseLayout";
import Content from "../../../layouts/sections/components/Content";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import TechPartners from "../../../pages/LandingPages/Partners/components/techPartners";
import EcoPartners from "../../../pages/LandingPages/Partners/components/ecosystemPartners";

function Careers() {
  return (
    <>
      <BaseLayout
        title="Partners"
        breadcrumb={[
          { label: "Home", route: "/Presentation" },
          { label: "Partners" },
        ]}
      >
        <Content title="Technology Partners" height="100%">
          <TechPartners />
        </Content>

        <Content title="Ecosystem Partners" height="100%">
           <EcoPartners/>
        </Content>
         
      </BaseLayout>

      <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
         
        </Grid>
      </Container>

    </>
  );
}

export default Careers;
