// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram"

// Material Kit 2 React components
import MKTypography from "./components/MKTypography";

// Images
import logoCT from "./assets/images/logo/cynefian.png";


const date = new Date().getFullYear();

export default {
  brand: {
    name: "Cynefian",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/cynefian",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/cynefian",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/cynefian",
    },
  
    
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", route: "/pages/landing-pages/about-us" },
        { name: "careers", route: "/pages/landing-pages/careers" },
        { name: "partners", route: "/pages/landing-pages/partners" },
        {/*}     { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },*/},
      ],
    },
    {
      name: "resources",
      items: [
     {/*   { name: "products", href: "https://iradesign.io/" },
        { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },,
        { name: "custom development", href: "https://services.creative-tim.com/" }*/},
        { name: "pricing", route: "/pages/pricing" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "status page", href: "https://status.cynefian.com" },
   {/*}     { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" }, */},
        { name: "contact us", route: "/pages/landing-pages/contact-us" },
    {/*}    { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" }, */},
        
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", route: "/pages/legal/terms-conditions"},
        { name: "privacy policy", route: "/pages/legal/privacy-policy"},
        { name: "refund policy", route: "/pages/legal/refund-policy" },
   {/*}      { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" }, */}
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} {" | "}
      <MKTypography
        component="a"
        href="https://cynefian.com"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Cynefian Pvt. Ltd.
      </MKTypography>
      .
    </MKTypography>
  ),
};
