import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";

import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";


function RPolicy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        
        sticky
      />
         
         <MKBox
        minHeight="20vh"
        width="100%"
        ></MKBox>


<MKBox component="section" py={{ xs: 0, sm: 4 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{
          mx: { xs: 4, lg: 5 },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          zIndex: 999, // Bring the black box to the front
        }}
      >
        {/*<MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
  />*/}
        <Container sx={{ position: "relative", zIndex: 2, py: 3 }}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white" >
              Cancellation & Refund Policy
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      
    </MKBox>

          <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
         <div>
    
         <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 8,
          mb: 4,
        }}
      >
        <MKTypography variant="h4" color="dark" >
   1. CANCELLATION
    </MKTypography>
    <MKTypography variant="body" color="dark" >
    <p>You may cancel a service at any time by contacting us at support@cynefian.com. However, we do not offer any prorated refunds for cancellations. If you cancel before the end of your billing cycle, your cancellation will take effect at the end of the cycle, and you will not be charged again. No refunds will be issued for early cancellation or for unused services.

.</p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >2. REFUNDS</MKTypography>
    <MKTypography variant="body" color="dark" >
   
    <p>
    For our IT products, refunds will only be issued for defective items. To be eligible for a refund, the defect must be a manufacturing defect, and you must contact us within 7 days from the purchase date.

Payments made towards completed IT services and consulting work are not eligible for refunds. Once the service has been rendered or the consulting work has been completed, these payments are final and non-refundable.

In exceptional circumstances, we may offer credits for future services; this will be at our sole discretion.


    </p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >3. PROCESSING REFUNDS</MKTypography>
    <p>Once your refund request is received and inspected, we will send you an email to notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment, within a certain number of days.


</p>
<br/>
<MKTypography variant="h4" color="dark" >4. LATE OR MISSING REFUNDS</MKTypography>
    <p>If you haven’t received a refund within a reasonable time from the day on which we sent you an email confirming that the refund would be issued, please contact us at support@cynefian.com
</p>
<br/>
  <MKTypography variant="h4" color="dark" >5. CONTACT US</MKTypography>
    <p>Should you have any queries regarding this Privacy Policy, about our processing of your personal data or wish to exercise your rights you can contact Cynefian's Data Protection Officer using this email address: support@cynefian.com. This is without prejudice to your right to launch a claim with your data protection authority.</p>
 
 </MKBox>
  </div>


        </Card>
   
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </>
  );
}

export default RPolicy;
