const imagesPrefix = "https://raw.githubusercontent.com/cynefian/public-assets/main/";

export default [
  {
    title: "Perks",
    description: "",
    items: [
      {
        image: `${imagesPrefix}/growth.png`,
        name: "Growth",
        desc:"We're growing really fast. Which means lots of opportunity for you to take on a new role. Take a class or join a lunch-and-learn. Get recognized.",
        count: 10,
        route: "",
      },
      {
        image: `${imagesPrefix}/mental-health.png`,
        name: "Mental Health",
        desc:"From Games, retreats, swags and pets; we help you stay healthy physically and mentally.",
        count: 14,
        route: "",
      },
      {
        image: `${imagesPrefix}/ownership.png`,
        name: "Ownership",
        desc:"We're all eligible for equity awards. That means when we play together, we win together.",
        count: 8,
        route: "",
      },
      {
        image: `${imagesPrefix}/work-life.png`,
        name: "Work Life Balance",
        desc:"Life is good. We have flexible hours, loads of time off, awesome events, flexible schedules and a remote work program.",
        count: 1,
        route: "",
      },
      {
        image: `${imagesPrefix}/allowance.png`,
        name: "Allowances",
        desc:"You Know :)",
        count: 11,
        route: "",
      },
      {
        image: `${imagesPrefix}/vacation.png`,
        name: "Time-Off",
        desc:"Parental leaves (more than legally required), time off to volunteer, sick leaves and vacation times - you get it all.",
        count: 11,
        route: "",
      },
    ],
  },
];
