import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";

import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";


function PPolicy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        
        sticky
      />
         
         <MKBox
        minHeight="20vh"
        width="100%"
        ></MKBox>


<MKBox component="section" py={{ xs: 0, sm: 4 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{
          mx: { xs: 4, lg: 5 },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          zIndex: 999, // Bring the black box to the front
        }}
      >
        {/*<MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
  />*/}
        <Container sx={{ position: "relative", zIndex: 2, py: 3 }}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white" >
              Privacy Policy
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      
    </MKBox>

          <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
         <div>
    
         <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 8,
          mb: 4,
        }}
      >
        <MKTypography variant="h4" color="dark" >
   1. INTRODUCTION
    </MKTypography>
    <MKTypography variant="body" color="dark" >
    <p>Cynefian Private Limited ("Company", "we", "us", "our") respects the privacy of our users ("user", "you", "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.</p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >2. DATA COLLECTION</MKTypography>
    <MKTypography variant="body" color="dark" >
   
    <p>
    We may collect and process the following data about you:
    <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 4,
        }}>
<ul>
        <li><b>Identity and Contact Data:</b> This includes data which relates to your identity such as your first name, last name, username, title, billing address, delivery address, email address, and telephone numbers.
</li>
        <li><b>Technical Data:</b> This may include information such as geolocation data, IP address, your login data, the type of device you used to access our site or services, your internet service provider, your device’s operating system, browser type, the pages you viewed and the order of those pages, and the date and time of your visit.
</li>
        <li><b>Usage Data:</b> This includes details of your usage of our website, products, and services, including traffic data, logs and other communication data and resources, as well as information about how you respond to our marketing communications.
</li>
        <li><b>Marketing and</b> Communications Data: This includes your preferences in receiving marketing from us and our third parties and your communication preferences.
</li>
</ul>
</MKBox>
    </p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >3. USE OF YOUR INFORMATION</MKTypography>
    <p>We use the collected data for various purposes:
    <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 4,
        }}>
<ul>
<li>To maintain and improve our website and services</li>
<li>To manage your account and provide you with customer support</li>
<li>To perform research and analysis about your use of our services</li>
<li>To develop and display content and advertising tailored to your interests on our website and other sites</li>
<li>To verify your eligibility and deliver prizes in connection with contests and sweepstakes</li>
<li>To enforce our terms and conditions</li>
<li>To manage our business and perform functions as otherwise described to you at the time of collection.</li>
</ul>
</MKBox>
</p>
<br/>
<MKTypography variant="h4" color="dark" >4. DATA DISCLOSURE</MKTypography>
    <p>We do not share your personal information with others except as indicated within this policy or when we inform you and give you an opportunity to opt-out of having your personal information shared.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >5. DATA SECURITY</MKTypography>
    <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >6. DATA RETENTION</MKTypography>
    <p>We retain personal information for as long as necessary to fulfill the purposes for which you provided it, comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >7. YOUR RIGHTS</MKTypography>
    <p>Under the data protection laws, you have a number of important rights. Those include rights to:
    <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mb: 4,
        }}>
<ul>
<li>Fair processing of information and transparency over how we use your use personal information</li>
<li>Access to your personal information</li>
<li>Require us to correct any mistakes in your information which we hold</li>
<li>Require the deletion of personal information concerning you in certain situations</li>
<li>Receive the personal information concerning you which you have provided to us, in a structured, commonly used, and machine-readable format</li>
<li>Object at any time to processing of personal information concerning you for direct marketing</li>
<li>Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you</li>
<li>Object in certain other situations to our continued processing of your personal information</li>
</ul>
</MKBox>
</p>
<br/>
<MKTypography variant="h4" color="dark" >8. CHANGES TO THE POLICY</MKTypography>
    <p>We will post any changes we make to our privacy policy on this page and, if they’re significant changes, we will notify you by sending you an email.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >9. CONTACT US</MKTypography>
    <p>Should you have any queries regarding this Privacy Policy, about our processing of your personal data or wish to exercise your rights you can contact Cynefian's Data Protection Officer using this email address: support@cynefian.com. This is without prejudice to your right to launch a claim with your data protection authority.</p>
 
 </MKBox>
  </div>


        </Card>
   
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </>
  );
}

export default PPolicy;
