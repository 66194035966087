// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../../examples/Cards/BlogCards/CenteredBlogCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Mission"
                    description="Cynefian's mission is to leverage technology for societal transformation, empowering rural communities through innovative IT solutions."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="Vision"
                    title="Vision"
                    description="Cynefian envisions a future where technology drives sustainable socio-economic development, bridging the digital divide and creating equal opportunities for all."
                  />

                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Values"
                    description="Cynefian upholds values of social responsibility, innovation, diversity, and collaboration, placing people and community at the core of their endeavors."
                  />
                   <MKTypography
                    variant="body2"
                    mt={3}
                  >
                    Our Values <ArrowForwardIcon sx={{ fontWeight: "bold" }}/>
                  </MKTypography>
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Goals"
                    description=" Cynefian aims to create unrivaled IT solutions, foster rural development, empower women, and drive positive change by integrating technology and local talent to address complex business challenges."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Learn more about us"
              description=" Explore collaboration opportunities and discover how Cynefian can help you transform."
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "get in touch",
              }}
            />
            
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
