// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import careerimg from "../../../../../../assets/images/career.png";

// Material Kit 2 React components
import MKBox from "../../../../../../components/MKBox";
import MKTypography from "../../../../../../components/MKTypography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CodeIcon from '@mui/icons-material/Code';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import { Link } from 'react-router-dom';

function CareersOne() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
          <MKBox component="img" src={careerimg} alt="Career Growth" width="100%"/>
            <MKTypography variant="body2" color="text" mb={2}>
              We're committed to building a culture where everyone has the opportunity to do meaningful work and be recognized for their efforts.
            </MKTypography>
           
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "right",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              <MKTypography>
             <Link to="/pages/landing-pages/about-us">
  More about us
  <ArrowForwardIcon sx={{ fontWeight: "bold" }}/>
</Link>
</MKTypography>
             
              
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <CodeIcon />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Empowerment through Technology
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                  At Cynefian, we believe in the transformative power of technology and its ability to create positive change. Joining our team means being part of an organization that is dedicated to leveraging technology for societal transformation, and rural development. If you are passionate about using your skills to make a difference and drive meaningful impact, Cynefian offers you the opportunity to be at the forefront of innovative IT solutions that uplift communities and empower individuals.
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

            
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <Diversity2Icon/>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Collaborative and Inclusive Culture
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                  We foster a culture of collaboration, where diverse perspectives and ideas are not only valued but encouraged. At Cynefian, we believe that the best solutions come from a collective effort and an inclusive approach. Our team consists of talented professionals from various backgrounds, working together towards a common goal. By joining Cynefian, you become part of a supportive community that encourages growth, nurtures talent, and fosters an environment of continuous learning and innovation.
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      coloredShadow="info"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <AutoGraphIcon/>
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Purpose-Driven Career Growth
                  </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                  Your career is not just about professional growth, it's about making a difference. Our company's values and objectives align with those who have a shared vision of creating a sustainable socio-economic development model. We provide ample opportunities for personal and professional development, offering mentorship programs, skill-building workshops, and exposure to cutting-edge technologies. As you advance in your career at Cynefian, you will have the satisfaction of knowing that your work contributes to the betterment of society and empowering rural communities.
                  
                  </MKTypography>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default CareersOne;