import React from "react";
import BaseLayout from "../../../layouts/sections/components/BaseLayout";
import Content from "../../../layouts/sections/components/Content";
import CareersOne from "../../../layouts/sections/page-sections/careers/components/CareersOne";
import Perks from "../../../pages/LandingPages/Careers/perks";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

function Careers() {
  return (
    <>
      <BaseLayout
        title="Careers"
        breadcrumb={[
          { label: "Home", route: "/Presentation" },
          { label: "Careers" },
        ]}
      >
        <Content title="Join our crew and Innovate" height="100%">
          <CareersOne />
          <Divider sx={{ my: 6 }} />
          <Perks />
        </Content>
         
      </BaseLayout>

      <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
         
        </Grid>
      </Container>

    </>
  );
}

export default Careers;
