import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import BlogCard from '../../examples/Cards/BlogCards/BackgroundBlogCard';

import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Divider from "@mui/material/Divider";

const LatestMediumPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@cynefian&api_key=zh3gcw4hziurikxezi9an0cxiqrz9v5m0xwi49rd');
        const data = await response.json();
        const allPosts = data.items.slice(0, 3); // Get latest 3 available posts

        setPosts(allPosts);
      } catch (error) {
        console.error('Error fetching Medium posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <MKBox component="section" py={2}>
    <Container>
    <Divider sx={{ my: 6 }} />
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <MKTypography variant="h4">Our Work</MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
        {posts.map((post) => (
          <Grid item xs={12} md={6} lg={4} key={post.guid}>
            <BlogCard
              title={post.title}
              image={post.thumbnail}
              description=""
              action={{
                type: 'external',
                route: post.link,
                label: 'Read More',
              }}
            />
          </Grid>
        ))}
        </Grid>

        <Grid
        container
        item
        xs={12}
        lg={12}
        justifyContent="right"
        sx={{ mx: "auto", mt:3, textAlign: "right" ,  justifyContent: "flex-end"}}
      >
          <MKTypography variant="body2">
            <a href="https://medium.com/@cynefian" target="_blank" rel="noreferrer">View More <ArrowForwardIcon sx={{ fontWeight: "bold" }}/></a>
          </MKTypography>
       </Grid>
       <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
    
  );
};

export default LatestMediumPosts;
