// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKInput from "../../../../components/MKInput";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import bgImage from "../../../../assets/images/examples/blog2.jpg";
import whatsapp from "../../../../assets/images/whatsapp.png";

import axios from 'axios';

function Contact() {

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const emailData = {
      sender: {
        name: e.target.name.value,
        email: e.target.email.value,
      },
      to: [
        {
          email: 'support@cynefian.com',
          name: 'Cynefian Support',
        },
      ],
      htmlContent: `<html><body><h1>${e.target.message.value}</h1></body></html>`,
      subject: e.target.subject.value,
      replyTo: {
        name: e.target.name.value,
        email: e.target.email.value,
      },
    };
  
    axios
      .post('https://api.sendinblue.com/v3/smtp/email', emailData, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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',
        },
      })
      .then((response) => {
        if (response.status === 201) {
          // Email sent successfully
          alert('Email sent successfully!');
          // Reset form fields
          e.target.reset();
        } else {
          // Email failed to send
          alert('Failed to send email. Please try again later.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle any errors that occurred during the request
        alert('An error occurred. Please try again later.');
      });
  };
  


  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Fill up the form and our Team will get back to you.
                    </MKTypography>

                    <MKTypography p={1} mt={2} variant="body2" color="white" opacity={0.8} mb={3}>
                      Or, you can reach us at:
                    </MKTypography>

                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={1}
                        fontWeight="regular"
                      >
                        (+91) 70225 16660
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={1}
                        fontWeight="regular"
                      >
                        support@cynefian.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={1}
                        fontWeight="regular"
                      >
                        1010/27 Mysore Road, Gundlupet - 571111, Karnataka, India
                      </MKTypography>
                    </MKBox>
                
                    <MKTypography p={1} mt={1} variant="body2" color="white" opacity={0.8} mb={3}>
                      Connect with us on Whatsapp:
                    </MKTypography>
                    <MKBox position="relative" display="flex" sx={{p:2, display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <MKBox component="img" src={whatsapp} alt="qr-whatsapp" style={{ width: '100%', maxWidth: '250px' }}  />
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
              <MKBox component="form" p={2} method="post" onSubmit={handleSubmit}>
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Say Hi!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      We&apos;d like to talk with you.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="My name is"
                          placeholder="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="name"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="My email is"
                          placeholder="Email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="email"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="I'm looking for"
                          placeholder="How can we help you"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="subject"
                          required
                          
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Your message"
                          placeholder="I want to say that..."
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          name="message"
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton variant="gradient" color="info" type="submit">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
