// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import onebyzero from "../../../../assets/images/partners/1by0 grit.jpg";
import startupindia from "../../../../assets/images/partners/startupindialogo_new.png";
import startupkarnataka from "../../../../assets/images/partners/startup karnataka.jpg";
import step from "../../../../assets/images/partners/STEP.jpeg";
import tie from "../../../../assets/images/partners/tie.png";
import kdem from "../../../../assets/images/partners/KDEM_Final-logo_1.png";
import lifedreams from "../../../../assets/images/partners/lifedreams.png";



function EcoSystemPartners() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          
          <Grid item xs={12} lg={12} sx={12}>
            <Stack>
                
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                     <MKBox component="img" src={startupindia} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Startup India
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                    ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

            
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={startupkarnataka} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Startup Karnataka
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={step} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    STEP
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>


              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={onebyzero} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    1by0
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>


              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={tie} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    TiE
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>
              

              

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={kdem} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    KDEM
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={lifedreams} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Life Dreams Foundation
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default EcoSystemPartners;