// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { Link } from 'react-router-dom';

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import MKSocialButton from "../../components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Presentation page sections
import Medium from "../../pages/medium"
import Testimonials from "../../pages/Presentation/sections/Testimonials";
import Download from "../../pages/Presentation/sections/Download";


// Material Kit 2 React examples
import RotatingCard from "../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "../../examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "../../assets/images/rotating-card-bg-front.jpeg";
import bgBack from "../../assets/images/rotating-card-bg-back.jpeg";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "../../assets/images/bg-presentation.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        
        sticky
      />
      <MKBox
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={12}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Strategy, Technology, Transformation.{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Cynefian holds a distinctive position in the industry with our mission that intertwines innovative IT solutions with rural development and women empowerment.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >




<MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon=""
                lg={12} 
                title={
                  <>
                    
                    Building a better tomorrow for all.
                  </>
                }
                description="Cynefian is a purpose-led, business and technology company."
              />
              <RotatingCardBack
                image={bgBack}
                lg={12} 
                title=" We are engineers focused on people, above all else."
                description="Our teams are driven to do what's right. We believe in generating success collaboratively, always working on the next big challenge.                "
             
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Essence"
                  description="At our core, Cynefian operates on Imagination, Individuality, Inclusivity, & Impact."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Vibe"
                  description="We make magic. We dream it, and then do it - reinventing what's possible."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Work"
                  description="We combine efficiency and technical expertise to provide innovative results."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Promise"
                  description="We deliver products and services to achieve innovation without compromise to quality or security."
                />
              </Grid>
            </Grid>
          </Grid>
</Grid>
          </Container>
          </MKBox>


         


          
      {/*    <Counters />
        
      
          <DesignBlocks /> 
        <Pages />
        <Information />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        
       
        */}

<Medium />
        {/*
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
              */}

        <Testimonials />
        <Download />
        
        <MKBox pt={4} pb={6}>
          <Container>
            <Grid
            container
            item
            xs={12}
            lg={6}
            mb={4}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h2">Your future starts today.</MKTypography>
            <MKTypography variant="body2">Industry knowledge. Technical expertise. Culture of innovation. We bring them together to help you unlock values and thrive in the age of intelligence.</MKTypography>
            
          
            </Grid>


            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Get in touch with us and begin your journey.
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Transformation at the Grass Roots level.
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Join%20@Cynefian%20on%20this%20incredible%20journey%20as%20we%20embark%20on%20transforming%20industries%20and%20redefining%20possibilities.%20Stay%20tuned%20for%20exciting%20updates,%20product%20launches,%20and%20insightful%20thought%20leadership%20content.%20%20%23Cynefian%20%23Innovation%20%23DigitalTransformation%20%23TechnologyRevolution"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
               
                <MKSocialButton
                  component="a"
                  href="https://www.linkedin.com/company/cynefian"
                  target="_blank"
                  color="linkedin"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-linkedin" />
                  &nbsp;Follow
                </MKSocialButton>
                <MKButton
  color="secondary"
  variant="contained"
  component={Link}
  to="/pages/landing-pages/contact-us"
>
  Get in touch
</MKButton>


               {/*
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
            */}
              </Grid>
            </Grid>
            
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
