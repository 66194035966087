// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import desicloudimg from '../../../../assets/images/partners/desicloud.jpg';
import aimlwareimg from '../../../../assets/images/partners/aimlware.jpeg';
import eeshavasyaimg from '../../../../assets/images/partners/desicloud.jpg';

function TechPartners() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          
          <Grid item xs={12} lg={12} sx={12}>
            <Stack>
                
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                     <MKBox component="img" src={desicloudimg} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    DesiCloud
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                    ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

            
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={aimlwareimg} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    AIMLWare Technologies
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <MKBox
                    display="flex"
                    alignItems="center"
                    p={2}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="xl"
                    >
                      <MKBox component="img" src={eeshavasyaimg} alt="Startup India" width="60%" opacity={0.6} />
                    </MKBox>
                    <MKTypography variant="body2" color="text" pl={2}>
                    Eeshavasya Technologies
                    </MKTypography>
                  </MKBox>
                </AccordionSummary>
                <AccordionDetails>
                  <MKTypography variant="body2">
                 ...
                  </MKTypography>
                </AccordionDetails>
              </Accordion>

              
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TechPartners;