// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "../../../examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import onebyzero from "../../../assets/images/partners/1by0 grit.jpg";
import aimlware from "../../../assets/images/partners/aimlware.jpeg";
import desicloud from "../../../assets/images/partners/desicloud.jpg";
import startupindia from "../../../assets/images/partners/startupindialogo_new.png";
import step from "../../../assets/images/partners/STEP.jpeg";


function Information() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h4">Testimonials</MKTypography>
          {/*<MKTypography variant="h2" color="info" textGradient mb={2}>
            1,679,477+ web developers
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Many Fortune 500 companies, startups, universities and governmental institutions love
            Creative Tim&apos;s products.
  </MKTypography> */}
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Vijayanad Gurudev"
              color="info"
              review="Cynefian stands as a beacon of innovation, shining a light on the untapped potential of rural India and women in technology."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Harsha Srinivasan"
              review="Cynefian's commitment to societal transformation truly sets it apart. The company does not just leverage technology for business growth, but also for the empowerment of rural communities and women. This unique blend of technological expertise and social commitment makes Cynefian a truly exceptional partner in the IT industry."
              rating={4.5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Sandeep Balasubramaniam"
              review="Cynefian's diverse IT service portfolio is a testament to its technical prowess and versatility. Whether it's custom software development, insightful IT consulting, or robust cybersecurity solutions, the company delivers each service with unmatched professionalism and a deep understanding of client needs. Their team's expertise in creating value-driven software solutions is impressive, contributing significantly to the success of businesses across various sectors."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
        <MKTypography variant="h2"  mb={2}>Our Partners</MKTypography>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
        
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={startupindia} alt="Startup India" width="60%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={step} alt="SJCE STEP" width="30%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={desicloud} alt="DesiCloud" width="40%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={aimlware} alt="AIML Ware" width="60%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={onebyzero}
              alt="1by0 Grit"
              width="70%"
              opacity={0.6}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
