import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";



import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";
import routes from "../../../routes";
import footerRoutes from "../../../footer.routes";

import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

function PPolicy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        
        sticky
      />
         
         <MKBox
        minHeight="20vh"
        width="100%"
        ></MKBox>


<MKBox component="section" py={{ xs: 0, sm: 4 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{
          mx: { xs: 4, lg: 5 },
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          zIndex: 999, // Bring the black box to the front
        }}
      >
        {/*<MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
  />*/}
        <Container sx={{ position: "relative", zIndex: 2, py: 3 }}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white" >
              Terms & Conditions
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      
    </MKBox>

          <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
         <div>
    
         <MKBox
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 8,
          mb: 4,
        }}
      >
        <MKTypography variant="h4" color="dark" >
   1. INTRODUCTION
    </MKTypography>
    <MKTypography variant="body" color="dark" >
    <p>Welcome to Cynefian Private Limited! These Terms and Conditions ("Terms") govern your use of our website located at www.cynefian.com and our services. Please read these Terms carefully. By accessing our site or using our services, you agree to be bound by these Terms and all terms incorporated by reference. If you do not agree to all of these terms, do not use our site or services.</p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >2. SERVICES</MKTypography>
    <MKTypography variant="body" color="dark" >
   
    <p>
    We provide various IT products and services through our website. In order to use these services, you may be required to provide personal details or other information.
    </p>
    </MKTypography>
    <br/>
    <MKTypography variant="h4" color="dark" >3. USER OBLIGATIONS</MKTypography>
    <p>By using this website and our services, you warrant that all data provided by you is accurate and up-to-date. Use of personal information submitted to or via the Site is governed by our Privacy Policy.
</p>
<br/>
<MKTypography variant="h4" color="dark" >4. INTELLECTUAL PROPERTY</MKTypography>
    <p>The Company owns all intellectual property rights in the website and in the material published on it. These works are protected by copyright laws worldwide. All such rights are reserved.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >5. LIMITATION OF LIABILITY</MKTypography>
    <p>Our liability for direct losses arising out of our negligence, breach of contract or any other cause of action arising out of or in connection with these Terms shall be limited to the total price of your Order. In no event shall we be liable for any loss of profits, goodwill, loss of business, loss of data or any other indirect or consequential loss or damage whatsoever.

</p>
    <br/>
    <MKTypography variant="h4" color="dark" >6. CHANGES TO TERMS AND CONDITIONS</MKTypography>
    <p>We reserve the right, at our discretion, to modify these Terms at any time. Changes will be posted on the website with a new effective date. Your continued use of our Services following the posting of changes will mean you accept those changes.</p>
    <br/>
    <MKTypography variant="h4" color="dark" >7. GOVERNING LAWS AND JURISDICTION</MKTypography>
    <p>These Terms are governed by the laws of India. Any disputes arising in connection with these terms shall be subject to the exclusive jurisdiction of the Courts of India.


</p>
<br/>

    <MKTypography variant="h4" color="dark" >8. CONTACT US</MKTypography>
    <p>f you have any questions about these Terms, please contact us at: support@cynefian.com</p>
 
 </MKBox>
  </div>


        </Card>
   
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

    </>
  );
}

export default PPolicy;
